import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config';
import { Pharmacy, defaultPharmacy } from '../../types/pharmacy';
import Modal from 'react-bootstrap/Modal';
import { hasRole, hasPharmacyEdit } from '../../utils/auth';
import { Role } from '../../types/role';
import AuditTrailTab from '../AuditTrailTab';
import DetailTab from './DetailTab';
import ServicesTab from './ServicesTab';
import OpeningHoursTab from './OpeningHoursTab';
import BankHolidayHoursTab from './BankHolidayHoursTab';
import PatientsTab from './PatientsTab';
import OffersTab from './OffersTab';
import { Group } from '../../types/group';

export default () => {
  const { pharmacyId } = useParams<{ pharmacyId: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);
  const [group, setGroup] = useState<Group | null>(null);
  const [initialPharmacy, setInitialPharmacy] = useState<Pharmacy | null>(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [hoursError, setHoursError] = useState(false);
  const [holsError, setHolsError] = useState(false);

  const fetchPharmacy = async () => {
    try {
      const url = Config.getConfigVar('pharmaciesEndpoint');
      const result = await axios.get(`${url}/${pharmacyId}`);

      // Deprecated 'openingTimes'
      if (result.data.openingTimes) {
        delete result.data.openingTimes;
      }

      if(result.data.groupId) {
        const groupUrl = Config.getConfigVar('getGroupsEndpoint');
        const groupResult = await axios.get(`${groupUrl}/${result.data.groupId}`);
        setGroup(groupResult.data.group);
      }

      setPharmacy(result.data);
      setInitialPharmacy(result.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Failed to load pharmacy');
    }
  };

  useEffect(() => {
    if (pharmacyId === 'new') {
      setInitialPharmacy(defaultPharmacy);
      setPharmacy(defaultPharmacy);
      setLoading(false);
    } else {
      fetchPharmacy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  // Call the NHS directory to check postcode matches ODS code. If does, update pharmacy
  // Should only be called after form validation
  const updatePharmacy = async () => {
    setModalLoading(true);
    try {
      // Only run post code check in prod
      let postcodeCheck;
      if(!process.env.REACT_APP_ENV_LABEL && pharmacy?.odsCode) {
          postcodeCheck = await axios.get(
          `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations/${pharmacy?.odsCode}`
        );
      }
      
      if (postcodeCheck && postcodeCheck.data.Organisation.GeoLoc.Location.PostCode !== pharmacy?.postcode) {
        setModalLoading(false);
        setShowSaveModal(false);
        setUpdateError(
          'Postcode does not match ODS code record - this is necessary for IM1 integration services to work properly'
        );
      } else {
        try {
          const url = Config.getConfigVar('updatePharmaciesEndpoint');
          
          const result = await axios.put(url, pharmacy);
          setUpdateError(null);
          setShowSaveModal(false);
          setModalLoading(false);
          if (result.data?.id !== pharmacyId) {
            history.push(`/pharmacies/${result.data?.id}`);
          } else {
            setLoading(true);
            fetchPharmacy();
          }
        } catch (e) {
          setModalLoading(false);
          setShowSaveModal(false);
          if(e.response.status === 409) {
            setUpdateError('There is already an account associated with that email address');
          } else {
            setError('Failed to save pharmacy');
          }
        }
      }
    } catch (e) {
      setModalLoading(false);
      setShowSaveModal(false);
      setUpdateError('There was an error checking the ODS code - please ensure it is correct');
    }
  };

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (!pharmacy) {
    return <Alert variant="danger">Error loading pharmacy</Alert>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      if (form.querySelectorAll('#pharmacy-tabs-tabpane-details .form-control:invalid').length) {
        setDetailsError(true);
      }
      if (form.querySelectorAll('#pharmacy-tabs-tabpane-hours .form-control:invalid').length) {
        setHoursError(true);
      }
      if (form.querySelectorAll('#pharmacy-tabs-tabpane-hols .form-control:invalid').length) {
        setHolsError(true);
      }

      setShowSaveModal(false);
    } else {
      setDetailsError(false);
      setHoursError(false);
      setHolsError(false);
      setShowSaveModal(true);
    }

    setValidated(true);
  };

  const detailsTitle = (
    <>
      Details{' '}
      {detailsError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  const hoursTitle = (
    <>
      Opening Hours{' '}
      {hoursError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  const holsTitle = (
    <>
      Bank Holidays{' '}
      {holsError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {updateError && <Alert variant="danger">{updateError}</Alert>}
      {/* Block enter key submission with a disabled submit */}
      <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>
      <Row className="my-3">
        <Col sm={6}>
          <h2>{pharmacyId === 'new' ? 'New Pharmacy' : initialPharmacy?.businessName}</h2>
        </Col>
        {hasPharmacyEdit() && (
          <Col sm={6}>
            <p className="text-right">
              <Button
                variant="secondary"
                disabled={initialPharmacy === pharmacy}
                onClick={() => {
                  setPharmacy(initialPharmacy);
                  setValidated(false);
                  setDetailsError(false);
                  setHoursError(false);
                }}
              >
                <FontAwesomeIcon icon="history" /> Reset
              </Button>{' '}
              <Button variant="primary" disabled={initialPharmacy === pharmacy} type="submit">
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </p>
          </Col>
        )}
      </Row>
      <Tabs defaultActiveKey="details" id="pharmacy-tabs" className="my-3">
        <Tab eventKey="details" title={detailsTitle} className="my-3 mx-2">
          <DetailTab pharmacy={pharmacy} group={group} setPharmacy={setPharmacy} pharmacyId={pharmacyId} />
        </Tab>
        <Tab eventKey="services" title="Services" className="my-3 mx-2">
          <ServicesTab pharmacy={pharmacy} setPharmacy={setPharmacy} />
        </Tab>
        <Tab eventKey="hours" title={hoursTitle} className="my-3 mx-2">
          <OpeningHoursTab pharmacy={pharmacy} setPharmacy={setPharmacy} />
        </Tab>
        <Tab eventKey="hols" title={holsTitle} className="my-3 mx-2">
          <BankHolidayHoursTab pharmacy={pharmacy} setPharmacy={setPharmacy} />
        </Tab>
        {(hasRole(Role.Admin_ReadOnly) ||
          hasRole(Role.Admin_Users_Edit) ||
          hasRole(Role.Admin_Users_Delete)) && (
          <Tab eventKey="patients" title="Patients" className="my-3 mx-2">
            <PatientsTab pharmacyId={pharmacy.id} />
          </Tab>
        )}
        {hasRole(Role.Admin_Full) && (
          <Tab eventKey="audittrail" title="Audit Trail" className="my-3 mx-2">
            <AuditTrailTab query={`entityId=${pharmacy.id}`} />
          </Tab>
        )}
        {process.env.REACT_APP_FEATURE_OFFERS ==='true' && (
          <Tab eventKey="offers" title="Offers" className="my-3 mx-2">
            <OffersTab pharmacy={pharmacy} setPharmacy={setPharmacy} />
          </Tab>
        )}
      </Tabs>
      <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
            Cancel
          </Button>
          {modalLoading ? (
            <Button variant="primary" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="sr-only">Saving...</span>
            </Button>
          ) : (
            <Button variant="primary" onClick={updatePharmacy}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export type PharmacyTabType = {
  pharmacy: Pharmacy;
  setPharmacy: (pharmacy: Pharmacy) => void;
};
