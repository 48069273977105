import axios from 'axios';
import { appInsights } from '..';

export type ConfigVars = {
  backOfficeApplicationInsightsKey: string;
  signInEndpoint: string;
  refreshTokenEndpoint: string;
  pharmaciesEndpoint: string;
  updatePharmaciesEndpoint: string;
  accountsEndpoint: string;
  patientsEndpoint: string;
  dateFormat: string;
  shortDateFormat: string;
  resetPasswordEndpoint: string;
  updateAccountStatusEndpoint: string;
  updatePatientEndpoint: string;
  gpSearchEndpoint: string;
  doctorListEndpoint: string;
  pharmacyServicesEndpoint: string;
  adminAccountEndpoint: string;
  updateAdminRolesEndpoint: string;
  updateAdminPharmaciesEndpoint: string;
  createAdminAccountEndpoint: string;
  createAdminB2CUserEndpoint: string;
  userPermissionsEndpoint: string;
  auditTrailEndpoint: string;
  gdprDeleteEndpoint: string;
  serviceRequestEndpoint: string;
  serviceRequestsEndpoint: string;
  reviewServiceRequestEndpoint: string;
  enableServiceBookingEndpoint: string;
  mapServicesFromAppointeddEndpoint: string;
  isPharmacyEnabledEndpoint: string;
  patientIM1AuditsEndpoint: string;
  correlationIM1AuditsEndpoint: string;
  resendVerificationEndpoint: string;
  pharmacyAccessLogsEndpoint: string;
  helpFilesListEndpoint: string;
  helpFilesUploadEndpoint: string;
  helpFilesDeleteEndpoint: string;
  portalAppDomain: string;
  forceLogoutEndpoint: string;
  backOfficeStatsEndpoint: string;
  patientsCsvEndpoint: string;
  ordersCsvEndpoint: string;
  getGroupsEndpoint: string;
  updateGroupEndpoint: string;
  updateAdminGroupsEndpoint: string;
  serviceBookingGetOwnerEndpoint: string;
  serviceBookingCreateOwnerEndpoint: string;
  pharmaciesCsvEndpoint: string;
  pharmacyOffersEndpoint: string;
  accountsCsvEndpoint: string;
  bookingsCsvEndpoint: string;
  serviceBookingGetServicesEndpoint: string;
};

const configEndpoint = process.env.REACT_APP_CONFIG_API as string;

class Config {
  configVars: ConfigVars = {
    backOfficeApplicationInsightsKey: '',
    signInEndpoint: '',
    refreshTokenEndpoint: '',
    pharmaciesEndpoint: '',
    updatePharmaciesEndpoint: '',
    accountsEndpoint: '',
    patientsEndpoint: '',
    dateFormat: 'dd/MM/yyyy HH:mm',
    shortDateFormat: 'dd/MM/yyyy',
    resetPasswordEndpoint: '',
    updateAccountStatusEndpoint: '',
    updatePatientEndpoint: '',
    gpSearchEndpoint: '',
    doctorListEndpoint: '',
    pharmacyServicesEndpoint: '',
    adminAccountEndpoint: '',
    updateAdminRolesEndpoint: '',
    updateAdminPharmaciesEndpoint: '',
    createAdminAccountEndpoint: '',
    createAdminB2CUserEndpoint: '',
    userPermissionsEndpoint: '',
    auditTrailEndpoint: '',
    gdprDeleteEndpoint: '',
    serviceRequestEndpoint: '',
    serviceRequestsEndpoint: '',
    reviewServiceRequestEndpoint: '',
    enableServiceBookingEndpoint: '',
    mapServicesFromAppointeddEndpoint: '',
    isPharmacyEnabledEndpoint: '',
    patientIM1AuditsEndpoint: '',
    correlationIM1AuditsEndpoint: '',
    resendVerificationEndpoint: '',
    pharmacyAccessLogsEndpoint: '',
    helpFilesListEndpoint: '',
    helpFilesUploadEndpoint: '',
    helpFilesDeleteEndpoint: '',
    portalAppDomain: '',
    forceLogoutEndpoint: '',
    backOfficeStatsEndpoint: '',
    patientsCsvEndpoint: '',
    ordersCsvEndpoint: '',
    getGroupsEndpoint: '',
    updateGroupEndpoint: '',
    updateAdminGroupsEndpoint: '',
    serviceBookingGetOwnerEndpoint: '',
    serviceBookingCreateOwnerEndpoint: '',
    pharmaciesCsvEndpoint: '',
    pharmacyOffersEndpoint: '',
    accountsCsvEndpoint: '',
    bookingsCsvEndpoint: '',
    serviceBookingGetServicesEndpoint: '',
  };

  async getConfig() {
    try {
      const latestConfig = (await axios.get(configEndpoint)).data;

      for (const key in latestConfig) {
        // Any values set in local env will override those returned from API
        (this.configVars as any)[key] = process.env[`REACT_APP_${key}`] ?? latestConfig[key];
      }
    } catch (e) {
      appInsights.trackException(e);
    }
  }

  getConfigVar(key: keyof ConfigVars) {
    return this.configVars[key];
  }
}

export default new Config();
