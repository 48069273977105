import React, { useEffect, useRef } from 'react';
import { OfferTabType } from './Offer';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';

export default ({ offer, setOffer }: OfferTabType) => {
  const textareaRef = useRef<any>();

  useEffect(() =>{
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [offer.description]);

  return (
  <>
    <h4 className="mb-4">Details</h4>
    <Form.Group as={Row}>   
      <Form.Label column sm={2}>
      Title
      </Form.Label>
      <Col sm={7}>
        <Form.Control
          type="text"
          required
          placeholder="Title"
          value={offer.title}
          onChange={(e: any) => {
          setOffer({
            ...offer,
            title: e.target.value,
          });
          }}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>   
      <Form.Label column sm={2}>
      Offer Code
      </Form.Label>
      <Col sm={7}>
        <Form.Control
          type="text"
          required={false}
          placeholder="Offer Code"
          value={offer.offerCode}
          onChange={(e: any) => {
          setOffer({
            ...offer,
            offerCode: e.target.value,
          });
          }}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>   
      <Form.Label column sm={2}>
      Live Date
      </Form.Label>
      <Col sm={7}>
        <DatePicker
          required
          dateFormat="dd/MM/yyyy"
          selected={new Date(offer.liveOnDate)}
          onChange={(date) => {
            if (date) {
              setOffer({
                ...offer,
                liveOnDate: date.toISOString()
              });
            }
          }}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>   
      <Form.Label column sm={2}>
      Expiry Date
      </Form.Label>
      <Col sm={7}>
        <DatePicker
          required
          dateFormat="dd/MM/yyyy"
          selected={new Date(offer.expiry)}
          onChange={(date) => {
            if (date) {
              setOffer({
                ...offer,
                expiry: date.toISOString()
              });
            }
          }}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>   
      <Form.Label column sm={2}>
      Description
      </Form.Label>
      <Col sm={7}>
        <Form.Control
          as="textarea"
          ref={textareaRef}
          required
          placeholder="Description"
          value={offer.description}
          onChange={(e: any) => {
          setOffer({
            ...offer,
            description: e.target.value,
          });
          }}
        />
      </Col>
    </Form.Group>
  </>
  );
}

