import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pharmacy } from '../../types/pharmacy';
import { Service } from '../../types/service';
import axios, { AxiosResponse } from 'axios';
import Config from '../../config';
import { Alert } from 'react-bootstrap';

type OptionType = {
  value: string;
  label: string;
};

export default () => {
  const [pharmacy, setPharmacy] = useState('');
  const [service, setService] = useState('');
  const [serviceSelectPlaceHolder, setServiceSelectPlaceHolder] = useState('Select a service');
  const [bookingMethod, setBookingMethod] = useState('');
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [createdFromDate, setCreatedFromDate] = useState<string>('');
  const [createdToDate, setCreatedToDate] = useState<string>('');
  const [showDetail, setShowDetail] = useState(true);
  const [exportHorizontal, setExportHorizontal] = useState(false);
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState('');
  const [convertedBookingStatus, setConvertedBookingStatus] = useState('');
  const [personalData, setPersonalData] = useState(false);

  const availablePharmacies: OptionType[] = pharmacies.map((pharmacy) => ({
    value: pharmacy.id,
    label: pharmacy.businessName,
  }));

  const availableServices: OptionType[] = services.map((service) => ({
    value: service.id,
    label: service.name,
  }));

  const options: OptionType[] = [
    { value: 'Upcoming', label: 'Upcoming' },
    { value: 'Cancelled By Pharmacy', label: 'Cancelled By Pharmacy' },
    { value: 'Cancelled By Patient', label: 'Cancelled By Patient' },
    { value: 'Missed', label: 'Missed' },
    { value: 'Attended', label: 'Attended' },
  ];

  const fetchPharmacies = async () => {
    try {
      const url = Config.getConfigVar('pharmaciesEndpoint');
      const result = await axios.get(url);
      setPharmacies(result.data);
    } catch (e) { }
  };

  const fetchServices = async () => {
    setServiceSelectPlaceHolder('Loading...');
    setServices([]);
    setService('');
    try {
      const url = Config.getConfigVar('serviceBookingGetServicesEndpoint');
      const result = await axios.post(url, {
        pharmacyId: pharmacy,
      });
      setServices(result.data);
    } catch (e) {
      console.log(e);
    } finally {
      setServiceSelectPlaceHolder('Select a service');
    }
  };

  useEffect(() => {
    fetchPharmacies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pharmacy !== '') {
      fetchServices();
    }
    // eslint-disable-next-line
  }, [pharmacy]);

  const resetSearchParameters = () => {
    setPharmacy('');
    setService('');
    setBookingMethod('');
    setFromDate('');
    setToDate('');
    setCreatedFromDate('');
    setCreatedToDate('');
    setShowDetail(true);
    setExportHorizontal(false);
    setSelectedBookingStatus('');
    setConvertedBookingStatus('');
    setPersonalData(false);
  };

  const createReport = async (isBooking: boolean) => {
    try {
      setDownloadingCSV(true);
      const result = await axios.post<any, AxiosResponse<any>>(
        Config.getConfigVar('bookingsCsvEndpoint'),
        {
          pharmacyId: pharmacy,
          serviceId: service,
          bookingMethod: bookingMethod,
          fromDate: fromDate,
          toDate: toDate,
          fromCreatedDate: createdFromDate,
          toCreatedDate: createdToDate,
          showVerticalReportDetail: showDetail,
          createHorizontalReport: exportHorizontal,
          isCancelledByPharmacy: selectedBookingStatus === 'Cancelled By Pharmacy' ? true : false,
          bookingStatus: convertedBookingStatus,
          showBookingDetails: isBooking,
          isDataCapturing: personalData
        },
        {
          responseType: 'blob',
        }
      );
      const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
    } finally {
      setDownloadingCSV(false);
    }
  };

  return (
    <>
      <Row className="my-3">
        <Col sm={3}>
          <h2>Booking Reports</h2>
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <Alert className='mb-3 pb-3' variant="primary">
            All of the following fields are optional. Each filled field can accelerate (Less Data) export speed.
          </Alert>
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <Select
            placeholder="Select a pharmacy"
            options={availablePharmacies}
            value={pharmacy ? availablePharmacies.find((x) => x.value === pharmacy) : null}
            onChange={(selected) => {
              setPharmacy((selected as OptionType).value);
            }}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <Select
            placeholder={serviceSelectPlaceHolder}
            options={availableServices}
            value={service ? availableServices.find((x) => x.value === service) : null}
            onChange={(selected) => {
              setService((selected as OptionType).value);
            }}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={3}>
          <DatePicker
            wrapperClassName="col-md-12 px-0"
            className="form-control"
            placeholderText="Booking Date From"
            dateFormat="dd/MM/yyyy"
            selected={fromDate ? new Date(fromDate) : null}
            maxDate={toDate ? new Date(toDate) : null}
            onChange={(date) => {
              if (date) {
                setFromDate(date.toISOString());
                setCreatedFromDate('');
                setCreatedToDate('');
              }
            }}
          />
        </Col>
        <Col sm={3}>
          <DatePicker
            wrapperClassName="col-md-12 px-0"
            className="form-control"
            placeholderText="Booking Date To"
            dateFormat="dd/MM/yyyy"
            selected={toDate ? new Date(toDate) : null}
            minDate={fromDate ? new Date(fromDate) : null}
            onChange={(date) => {
              if (date) {
                setToDate(date.toISOString());
                setCreatedFromDate('');
                setCreatedToDate('');
              }
            }}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={3}>
          <DatePicker
            wrapperClassName="col-md-12 px-0"
            className="form-control"
            placeholderText="Booking Created Date From"
            dateFormat="dd/MM/yyyy"
            maxDate={createdToDate ? new Date(createdToDate) : new Date()}
            selected={createdFromDate ? new Date(createdFromDate) : null}
            onChange={(date) => {
              if (date) {
                setCreatedFromDate(date.toISOString());
                setFromDate('');
                setToDate('');
              }
            }}
          />
        </Col>
        <Col sm={3}>
          <DatePicker
            wrapperClassName="col-md-12 px-0"
            className="form-control"
            placeholderText="Booking Created Date To"
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            minDate={createdFromDate ? new Date(createdFromDate) : null}
            selected={createdToDate ? new Date(createdToDate) : null}
            onChange={(date) => {
              if (date) {
                setCreatedToDate(date.toISOString());
                setFromDate('');
                setToDate('');
              }
            }}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <Select
            name="booking-status-select"
            placeholder="Please select a status"
            options={options}
            value={
              selectedBookingStatus ? options.find((x) => x.value === selectedBookingStatus) : null
            }
            onChange={(selected) => {
              const value = (selected as OptionType).value;

              if (value === "Cancelled By Pharmacy" || value === "Cancelled By Patient") {
                setConvertedBookingStatus("Cancelled");
              } else {
                setConvertedBookingStatus(value);
              }

              setSelectedBookingStatus(value);
            }}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <hr />
          <p className='mb-0 pb-0'>The following two checkboxes just available for reports. They do not change anything for <strong>Export Bookings</strong> option.</p>
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <Form.Check
            type="checkbox"
            checked={showDetail}
            label="Show detailed report"
            onChange={() => {
              setShowDetail(!showDetail);
              if (!showDetail) {
                setExportHorizontal(false);
              }
            }}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <Form.Check
            type="checkbox"
            checked={exportHorizontal}
            label="Create a horizontal report"
            onChange={() => {
              setExportHorizontal(!exportHorizontal);
              if (!exportHorizontal) {
                setShowDetail(false);
              }
            }}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <hr />
          <p className='mb-0 pb-0'>If you select the following checkbox, the system exports just <strong>Personal Data Typed Forms</strong>. If you do not select it, the system exports all bookings except <strong>Personal Data Typed Forms</strong></p>
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={6}>
          <Form.Check
            type="checkbox"
            checked={personalData}
            label="Export Personal Data Typed Forms' results"
            onChange={() => {
              setPersonalData(!personalData);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Button
            href="#"
            onClick={async () => await createReport(true)}
            className="float-right"
            disabled={downloadingCSV}
          >
            <FontAwesomeIcon icon={downloadingCSV ? 'download' : 'plus-circle'} />{' '}
            {downloadingCSV ? 'Exporting...' : 'Export Bookings'}
          </Button>{' '}
          <Button
            href="#"
            onClick={async () => await createReport(false)}
            className="float-right mr-2"
            disabled={downloadingCSV}
          >
            <FontAwesomeIcon icon={downloadingCSV ? 'download' : 'plus-circle'} />{' '}
            {downloadingCSV ? 'Exporting...' : 'Export Report'}
          </Button>{' '}
          <Button
            variant="secondary"
            className="float-right mr-2"
            onClick={() => resetSearchParameters()}
          >
            <FontAwesomeIcon icon="history" /> Reset
          </Button>
        </Col>
      </Row>
    </>
  );
};
